import { useEffect, useState } from "react";
import { Container, Form } from "react-bootstrap";
import { getPingSessionSummaries } from "../Api/PingSessionApi";
import { FormatDate } from "../Helpers/DateTimeHelper";

export const SessionsSubBar = ({
  onSessionIdChange,
  showMenu
}) => {
  const [sessionSummaries, setSessionSummaries] = useState([]);
  const [selectedSessionId, setSelectedSessionId] = useState(0);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getSessions();
  }, []);

  useEffect(() => {
    onSessionIdChange(selectedSessionId);
  }, [selectedSessionId]);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);

    if (urlParams.has("sessionId")) {
      setSelectedSessionId(urlParams.get("sessionId").toLowerCase());
    } else {
      setSelectedSessionId(0);
    }
  }, [sessionSummaries]);

  const getSessions = () => {
    setLoading(true);
    getPingSessionSummaries().then((data) => {
      setSessionSummaries(data);
      setLoading(false);
      console.log("sessions", data);
    });
  };

  const sessionChange = (event) => {
    setSelectedSessionId(event.target.value);

    const urlParams = new URLSearchParams(window.location.search);
    urlParams.set("sessionId", event.target.value);
    window.history.replaceState(
      {},
      "",
      `${window.location.pathname}?${urlParams}`
    );
  };

  const sessionSummaryName = (sessionSummary) => {
    if (sessionSummary.originLocationDescription != null) {
      return (
        FormatDate(sessionSummary.createDate) +
        " - " +
        sessionSummary.originLocationDescription +
        " to " +
        (sessionSummary.destinationLocationDescription ?? "TBD") +
        " (" +
        (sessionSummary.pingCount ?? "In Progress") +
        ")"
      );
    }
    return (
      FormatDate(sessionSummary.createDate) +
      " (" +
      sessionSummary.pingCount +
      ")"
    );
  };

  return (
    <div
      style={{
        position: "relative",
        flexDirection: "row",
        padding: "0.5rem",
        zIndex: 50,
        backgroundColor: "#393040",
        display: showMenu ? "flex" : "none",
      }}
    >
      <Container>
        <Form.Select onChange={sessionChange} value={selectedSessionId}>
          <option value={0}>{!loading && "Select a session"}{loading && "Loading your sessions..."}</option>
          {sessionSummaries &&
            sessionSummaries.map((sessionSummary) => {
              return (
                <option value={sessionSummary.pingSessionId} style={{backgroundColor:(sessionSummary.isOverwatchBonded ? '#a9c9fc' : '')}}>
                  {sessionSummaryName(sessionSummary)}{" "}{sessionSummary.isOverwatchBonded && " (" + sessionSummary.userId + ")"}
                </option>
              );
            })}
        </Form.Select>
      </Container>
    </div>
  );
};
