// api.js
import axios from 'axios';

const api = axios.create({
    baseURL: process.env.REACT_APP_SERVER_URL
});

// api.js continued
api.interceptors.response.use(
    response => response,
    error => {
        if (error.response && error.response.status === 401) {
            // Redirect to login page
            window.location.href = '/login?expired=true';
        }
        return Promise.reject(error);
    }
);

export default api;
