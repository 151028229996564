import { Feature } from "ol";
import olView from "ol/View";
import { LineString, Point } from "ol/geom.js";
import { fromLonLat } from "ol/proj";
import { Fill, Icon, Stroke, Style } from "ol/style.js";
import CircleStyle from "ol/style/Circle.js";
import { useEffect, useMemo, useState } from "react";
import { getPingSession } from "../../Api/PingSessionApi.js";
import PinPointNavbar from "../../Components/PinPointNavbar/PinPointNavbar.js";
import { SessionsMapControlbar } from "../../Components/SessionsMapControlsBar.js";
import { SessionsSubBar } from "../../Components/SessionsSubBar.js";
import { useInterval } from "../../Hooks/UseInterval.js";
import Controls from "../../MapComponents/Controls/Controls";
import FullScreenControl from "../../MapComponents/Controls/FullScreenControl";
import Layers from "../../MapComponents/Layers/Layers";
import TileLayer from "../../MapComponents/Layers/TileLayer";
import VectorLayer from "../../MapComponents/Layers/VectorLayer";
import Map from "../../MapComponents/Map/Map";
import Overlays from "../../MapComponents/Overlays/Overlays.js";
import PinPointInfoOverlay from "../../MapComponents/Overlays/PinPointInfoOverlay.js";
import MyVectorSource from "../../MapComponents/Source/VectorSource";
import osm from "../../MapComponents/Source/osm";
import "./SessionViewer.css";

export const SessionViewer = () => {
  const [features, setFeatures] = useState([]);
  const [lineFeatures, setLineFeatures] = useState([]);
  const [infoOverlayFeature, setInfoOverlayFeature] = useState(null);
  const [autoUpdate, setAutoUpdate] = useState(false);
  const [autoCenter, setAutoCenter] = useState(false);
  const [autoDrawLines, setAutoDrawLines] = useState(false);
  const [performanceDraw, setPerformanceDraw] = useState(false);
  const [showMenu, setShowMenu] = useState(true);
  const [selectedSessionId, setSelectedSessionId] = useState(0);
  const [displayPoints, setDisplayPoints] = useState(true);

  const view = useMemo(
    () =>
      new olView({
        center: fromLonLat([-0.38466814, 51.404773]),
        zoom: 12,
      }),
    []
  );

  const iconStyle = useMemo(() => {
    return (feature) => {
      if (feature.getGeometry() instanceof Point) {
        const index = feature.get("index");
        const totalPings = feature.get("totalPings");
        if (index == 0) {   const iconStyle = new Style({
          image: new Icon({
            anchor: [0.5, 20],
            anchorXUnits: "fraction",
            anchorYUnits: "pixels",
            src: "flag.png",
          }),
        });
        return iconStyle;
        } else if (index == totalPings - 1) {
          const iconStyle = new Style({
            image: new Icon({
              anchor: [0.5, 20],
              anchorXUnits: "fraction",
              anchorYUnits: "pixels",
              src: "start.png",
            }),
          });
          return iconStyle;
        }
      }
    };
  }, []);

  const vectorStyle = useMemo(() => {
    // Cache for point style
    const pointStyleCache = {};
    // Cache for line style
    const lineStyleCache = {};

    return (feature) => {
      if (feature.getGeometry() instanceof Point) {
        let radius = 6;
        
        // Check if we've cached this style before
        if (pointStyleCache[radius]) {
          return pointStyleCache[radius];
        }

        const newStyle = new Style({
          image: new CircleStyle({
            fill: new Fill({ color: 'rgba(255, 0, 204, 0.4)' }),
            stroke: new Stroke({ color: 'rgba(255, 0, 204,0.6)', width: 1 }),
            points: 4,
            radius: 2
          }),
        });

        pointStyleCache[radius] = newStyle;
        return newStyle;
      } else if (feature.getGeometry() instanceof LineString) {
        let speed = feature.get("speed");
        let color = "rgba(0,0,0,1)";
        if (speed !== null) {
          speed = speed / 1.609;
          const clampedSpeed = Math.min(Math.max(speed, 0), 70);
          const redComponent = Math.round((clampedSpeed / 70) * 255);
          const blueComponent = Math.round(255 - (clampedSpeed / 70) * 255);
          color = `rgba(${redComponent}, 0, ${blueComponent}, 1)`;
        }

        // Check if we've cached this style before
        if (lineStyleCache[color]) {
          return lineStyleCache[color];
        }

        // Create and cache the style
        const newStyle = new Style({
          stroke: new Stroke({
            color: color,
            width: 5,
          }),
        });
        lineStyleCache[color] = newStyle;
        return newStyle;
      }
    };
  }, []);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);

    if (urlParams.has("shownavbar")) {
      setShowMenu(urlParams.get("shownavbar") !== "false");
    }

    if(urlParams.has("sharedSessionId")) {
      
    }

    loadPings(true);
  }, []);

  useEffect(() => {
    if (
      autoUpdate &&
      (selectedSessionId !== null || `${selectedSessionId}` !== "0")
    ) {
      loadPings(true);
    }
  }, [autoUpdate, selectedSessionId]);

  useEffect(() => {
    setLineFeatures([]);
    loadPings(false, true);
  }, [selectedSessionId]);

  useEffect(() => {
    if (performanceDraw) {
      setFeatures(features.slice(0, 25));
    } else {
      loadPings();
    }
  }, [performanceDraw]);

  useInterval(
    () => {
      loadPings(autoCenter);
    },
    autoUpdate ? 5000 : null
  );

  useEffect(() => {
    handleLineGenerate(autoDrawLines);
  }, [autoDrawLines]);

  useEffect(() => {
    if (autoDrawLines) {
      handleLineGenerate(true);
    }
  }, [features]);

  const loadPings = async (centerLatest = false, centerAll = false) => {
    if (selectedSessionId) {
      getPingSession(selectedSessionId).then((pingSession) => {
        const builtFeatures = pingSession.pings.map((ping, index) => {
          let point = new Point(
            fromLonLat([
              ping.position.coordinates[1],
              ping.position.coordinates[0],
            ])
          );
          let feature = new Feature(point);
          feature.set("id", ping.pingId);
          feature.set("createDate", ping.createDate);
          feature.set("pingSessionId", ping.pingSessionId);
          feature.set("speed", ping.speedKilometresPerHour);
          feature.set("acceleration", ping.accelerationMetresPerSecond);
          feature.set("index", index);
          feature.set("totalPings", pingSession.pings.length);
          return feature;
        });

        if (performanceDraw) {
          setFeatures(builtFeatures.slice(0, 25));
        } else {
          setFeatures(builtFeatures);
        }

        if (centerLatest && builtFeatures.length > 0) {
          const latestFeature = builtFeatures[0];
          view.setCenter(latestFeature.getGeometry().getCoordinates());
        }

        if (centerAll && builtFeatures.length > 0) {
          let minX = Infinity,
            minY = Infinity,
            maxX = -Infinity,
            maxY = -Infinity;

          for (let i = 0; i < builtFeatures.length; i++) {
            const f = builtFeatures[i];
            const cords = f.getGeometry().flatCoordinates;
            const x = cords[0];
            const y = cords[1];
            if (x < minX) minX = x;
            if (y < minY) minY = y;
            if (x > maxX) maxX = x;
            if (y > maxY) maxY = y;
          }

          const buffer = 2000;
          const bufferedExtent = [
            minX - buffer,
            minY - buffer,
            maxX + buffer,
            maxY + buffer,
          ];
          // Zoom to the extent
          view.fit(bufferedExtent, { duration: 1000 });
        }
      });
    }
  };

  const handlePointerClick = (e) => {
    const map = e.map;
    const features = map.getFeaturesAtPixel(e.pixel, {
      hitTolerance: 4,
    });
    const pointFeatures = features.filter(
      (feature) => feature.getGeometry() instanceof Point
    );

    if (pointFeatures.length > 0) {
      setInfoOverlayFeature(pointFeatures[0]);
    } else {
      setInfoOverlayFeature(null);
    }
  };

  const handleNextFeatureClick = (feature, direction) => {
    // find index of existing feature by Id
    const index = features.findIndex((f) => f.get("id") === feature.get("id"));

    const nextFeature = features[index + direction];
    setInfoOverlayFeature(nextFeature);

    view.setCenter(nextFeature.getGeometry().getCoordinates());
  };

  const handleSessionIdChange = (e) => {
    if (e === "0") {
      setSelectedSessionId(0);
      return;
    }
    setSelectedSessionId(e);
  };

  const handleLineGenerate = (show) => {
    if (show) {
      const lineFeatures = [];
      for (let i = 0; i < features.length - 1; i++) {
        const f = features[i];
        const nextFeature = features[i + 1];
        const line = new Feature({
          geometry: new LineString([
            f.getGeometry().getCoordinates(),
            nextFeature.getGeometry().getCoordinates(),
          ]),
          speed: nextFeature.get("speed"),
        });
        lineFeatures.push(line);
      }
      setLineFeatures(lineFeatures);
    } else {
      setLineFeatures([]);
    }
  };

  return (
    <div style={{ height: "100vh", display: "flex", flexDirection: "column" }}>
      {showMenu && (
        <PinPointNavbar        />
      )}
      <SessionsMapControlbar
              autoUpdate={autoUpdate}
              setAutoUpdate={setAutoUpdate}
              autoCenter={autoCenter}
              setAutoCenter={setAutoCenter}
              performanceDraw={performanceDraw}
              setPerformanceDraw={setPerformanceDraw}
              displayPoints={displayPoints}
              setDisplayPoints={setDisplayPoints}
              autoDrawLines={autoDrawLines}
              setAutoDrawLines={setAutoDrawLines}
            />
      <SessionsSubBar
        onSessionIdChange={handleSessionIdChange}
        showMenu={showMenu}
      />
      <div className="sessionViewMapContainer">
        <Map view={view} onClick={handlePointerClick}>
          <Layers>
            <TileLayer source={osm()} zIndex={0} />
            <VectorLayer style={iconStyle} zIndex={4}>
              <MyVectorSource features={features.filter(x=>x.get("index") == 0 || x.get("index") == features.length - 1)} display={!performanceDraw} />
            </VectorLayer>
            <VectorLayer style={vectorStyle} zIndex={3}>
              <MyVectorSource features={lineFeatures} />
            </VectorLayer>
            <VectorLayer style={vectorStyle} zIndex={2}>
              <MyVectorSource features={features} display={displayPoints} />
            </VectorLayer>
          </Layers>
          <Controls>
            <FullScreenControl />
          </Controls>
          <Overlays>
            <PinPointInfoOverlay
              feature={infoOverlayFeature}
              onNextFeatureClick={handleNextFeatureClick}
            />
          </Overlays>
        </Map>
      </div>
    </div>
  );
};
