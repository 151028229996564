import { CurrentUser } from "../Helpers/CurrentUser";
import api from "./api";

export const getPingSessionSummaries = async () => {
	return api
        .get(process.env.REACT_APP_SERVER_URL + "/PingSession/GetSummaries", {headers: {"Authorization":CurrentUser.getToken()}})
		.then((response) => {
			return response.data;
		});
}

export const getPingSession = async (pingSessionId) => {
    return api
        .get(process.env.REACT_APP_SERVER_URL + `/PingSession/GetById?Id=${pingSessionId}`, {headers: {"Authorization":CurrentUser.getToken()}})
        .then((response) => {
            return response.data;
        });
}

export const deletePingSession = async (pingSessionId) => {
    return api
        .delete(process.env.REACT_APP_SERVER_URL + `/PingSession/delete?Id=${pingSessionId}`, {headers: {"Authorization":CurrentUser.getToken()}})
        .then((response) => {
            return response.data;
        });
}

export const getSharedPingSession = async (sharedSessionId) => {
    return api
        .get(process.env.REACT_APP_SERVER_URL + `/SharedSession?sharedSessionId=${sharedSessionId}`)
        .then((response) => {
            return response.data;
        });
}

export const sharePingSession = async (pingSessionId) => {
    return api
        .post(process.env.REACT_APP_SERVER_URL + `/SharedSession?pingSessionId=${pingSessionId}`,{}, {headers: {"Authorization":CurrentUser.getToken()}})
        .then((response) => {
            return response.data;
        });
}