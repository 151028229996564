import { useEffect } from "react";
import { Container, FormCheck } from "react-bootstrap";

export const SessionsMapControlbar = ({showMenu = true, autoUpdate, setAutoUpdate, autoCenter, setAutoCenter, performanceDraw, setPerformanceDraw, displayPoints, setDisplayPoints, autoDrawLines, setAutoDrawLines}) => {
    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
    
        if(urlParams.has("autocenter")) {
          setAutoCenter(urlParams.get("autocenter") === "true");
        }
    
        if(urlParams.has("autoupdate")) {
          setAutoUpdate(urlParams.get("autoupdate") === "true");
        }
    
        if(urlParams.has("performanceDraw")){
          setPerformanceDraw(urlParams.get("performanceDraw") === "true");
        }
    
        if(urlParams.has("drawLines")) {
          setAutoDrawLines(urlParams.get("drawLines") === "true");
        }
      }, []);

  return (   
    <div
      style={{
        position: "relative",
        flexDirection: "row",
        padding: "0.5rem",
        zIndex: 50,
        backgroundColor: "#393040",
        display: showMenu ? "flex" : "none",
        color:'white'
      }}
    >
      <Container style={{alignItems:'center', justifyContent:'space-between', display:'flex', flexWrap:'wrap'}}>
      <FormCheck
        type="switch"
        label="Auto Update Pings"
        className="ms-auto"
        checked={autoUpdate}
        onChange={(e) => setAutoUpdate(e.target.checked)}
        style={{margin:'auto'}}
      />
      <FormCheck
        type="switch"
        label="Auto Center"
        className="ms-auto"
        checked={autoCenter}
        onChange={(e) => setAutoCenter(e.target.checked)}
        style={{margin:'auto'}}
      />
      <FormCheck
        type="switch"
        label="Performance Draw"
        className="ms-auto"
        checked={performanceDraw}
        onChange={(e) => setPerformanceDraw(e.target.checked)}
        style={{margin:'auto'}}
      />
      <FormCheck
        type="switch"
        label="Hide Points"
        className="ms-auto"
        checked={!displayPoints}
        onChange={(e) => setDisplayPoints(!e.target.checked)}
        style={{margin:'auto'}}
      />
      <FormCheck
        type="switch"
        label="Draw Lines"
        className="ms-auto"
        checked={autoDrawLines}
        onChange={(e) => setAutoDrawLines(e.target.checked)}
        style={{margin:'auto'}}
      />
      </Container>
    </div>
  );
};
