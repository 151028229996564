export class CurrentUser {
  // Key to use when saving/retrieving token from localStorage
  static TOKEN_KEY = 'pinpoint_token';

  // Save token to localStorage
  static saveToken(token) {
    localStorage.setItem(CurrentUser.TOKEN_KEY, token);
  }

  // Retrieve token from localStorage
  static getToken() {
    return localStorage.getItem(CurrentUser.TOKEN_KEY);
  }

  // Remove token from localStorage
  static removeToken() {
    localStorage.removeItem(CurrentUser.TOKEN_KEY);
  }
}