import { Overlay } from "ol";
import { fromLonLat, toLonLat } from "ol/proj";
import { useContext, useEffect, useRef, useState } from "react";
import { Button } from "react-bootstrap";
import MapContext from "../Map/MapContext";

const PinPointInfoOverlay = ({feature, onNextFeatureClick}) => {
  const [overlay, setOverlay] = useState(null);
  const overlayRef = useRef();
  const { map } = useContext(MapContext);

  useEffect(() => {
    const ov = new Overlay({
      element: overlayRef.current,
      positioning: "top-center",
      stopEvent: true,
      offset: [0, +10],
    });

    ov.setPosition(fromLonLat([-0.37960781, 51.403741]));

    setOverlay(ov);
  }, []);
 
  useEffect(() => {
    if (!overlay || !feature) return;
    overlay.setPosition(feature.getGeometry().getCoordinates());
  }, [overlay, feature]);

  useEffect(() => {
    if (!map || !overlay) return;
    setTimeout(() => {
      map.addOverlay(overlay);
    }, 1000);  // 1 second delay

    return () => { 
      map.removeOverlay(overlay);
    };
  }, [overlay, map]);

  const displaySpeed = (speed) => {
    if(speed === undefined) {
      return "Unknown";
    } else {
      return (speed / 1.609).toFixed(0) + " mph";
    }
  }

  const displayAcceleration = (acceleration) => {
    if(acceleration === undefined || acceleration === null) {
      return "Unknown";
    } else {
      return acceleration.toFixed(2) + " m/s";
    }
  };

  const displayTime = (dateTime) => {
    if(dateTime === undefined) {
      return "Unknown";
    } else {
      return dateTime.toLocaleString();
    }
  }

  return (
    <p
      className="overlay-box"
      ref={overlayRef}
      style={{ display: !feature ? "none" : "block" }}
    >
      {feature && (
        <>
        <p>Time: {displayTime(feature.get("createDate"))}</p>
        <p>Speed: {displaySpeed(feature.get("speed"))}</p>
        <p>Accel: {displayAcceleration(feature.get("acceleration"))}</p>
          <p>
            Latitude: {toLonLat(feature.getGeometry().getCoordinates())[1]}
          </p>
          <p>Longitutde: {toLonLat(feature.getGeometry().getCoordinates())[0]}</p>
        <Button onClick={()=>{onNextFeatureClick(feature, 1)}} size="sm" variant="danger">Previous</Button>
        <Button style={{float:"right"}} onClick={()=>{onNextFeatureClick(feature, -1)}} size="sm" variant="success">Next</Button>
        </>
      )}
    </p>
  );
};
export default PinPointInfoOverlay;
