import { useEffect, useState } from "react";
import { Button, Table } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { deletePingSession, getPingSessionSummaries, sharePingSession } from "../../Api/PingSessionApi";
import { FormatDate, GetDuration } from "../../Helpers/DateTimeHelper";

export const Sessions = () => {
    // Get the navigate function
    const navigate = useNavigate();

    const [pingSessions, setPingSessions] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
      reloadPingSessions();
    }, []);

    const reloadPingSessions = () => {
      setLoading(true);
      getPingSessionSummaries().then((data) => {
        setPingSessions(data);
        setLoading(false);
      });
    };

    const pingSessionActions = (pingSessionId) => {
      return (
        <>
          <Button
            size="sm"
            variant="success"
            onClick={() => {
              navigate("/?sessionId=" + pingSessionId);
            }}
            style={{ marginRight: "10px" }}
          >
            View
          </Button>
          <Button
            size="sm"
            variant="danger"
            style={{ marginRight: "10px" }}
            onClick={() => {
              deletePingSession(pingSessionId).then(() => {
                reloadPingSessions();
              });
            }}
          >
            Delete
          </Button>
          <Button
            size="sm"
            variant="primary"
            onClick={() => {
              sharePingSession(pingSessionId).then((sharedSessionId) => {
                navigate("/SharedSession?sharedSessionId=" + sharedSessionId);
              });
            }}
          >
            Share
          </Button>
        </>
      );
    };

    return (
      <>
        <h2 style={{ marginTop: "20px" }}>Your Sessions</h2>
        <Table striped bordered hover variant="">
          <thead>
            <tr>
              <th>Origin</th>
              <th>Start</th>
              <th>Destination</th>
              <th>End</th>
              <th>Duration (Minutes)</th>
              <th># Pings</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {pingSessions.length > 0 && pingSessions.map((pingSession) => {
              return (
                <tr>
                  <td>{pingSession.originLocationDescription}</td>
                  <td>{FormatDate(pingSession.createDate)}</td>
                  <td>{pingSession.destinationLocationDescription}</td>
                  <td>{pingSession.endDate ? FormatDate(pingSession.endDate) : "In Progress"}</td>
                  <td>{pingSession.endDate ? GetDuration(pingSession.createDate, pingSession.endDate) : "N/A"}</td>
                  <td>{pingSession.pingCount ?? "In Progress"}</td>
                  <td>{pingSessionActions(pingSession.pingSessionId)}</td>
                </tr>
              );
            })}
            {pingSessions.length === 0 && !loading && (
              <tr>
                <td colSpan="7">No Sessions Yet</td>
              </tr>
            )}
          </tbody>
        </Table>
        {loading && <p><b>Loading Sessions...</b></p>}
      </>
    );
};
