export const FormatDate = (dateTimeString) => {
    // Parse the date-time string into a Date object
    const date = new Date(dateTimeString);

    // Get the individual components of the date
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
    const day = String(date.getDate()).padStart(2, "0");
    const hour = String(date.getHours()).padStart(2, "0");
    const minute = String(date.getMinutes()).padStart(2, "0");

    // Format and return the date-time string
    return `${year}-${month}-${day} ${hour}:${minute}`;
  };

export const GetDuration = (startDateTimeString, endDateTimeString) => {
    // Parse the date-time strings into Date objects
    const startDate = new Date(startDateTimeString);
    const endDate = new Date(endDateTimeString);

    // Get the difference between the dates in milliseconds
    const differenceInMilliseconds = endDate - startDate;

    // Convert the difference to minutes and seconds
    const minutes = Math.floor(differenceInMilliseconds / 60000);

    // Format and return the duration string
    return `${minutes}`;
  };