import axios from "axios";

export const login = async (email, password) => {
	return axios
		.post(process.env.REACT_APP_SERVER_URL + "/user/login", { email, password });
}

export const register = async (email, password) => {
	return axios
		.post(process.env.REACT_APP_SERVER_URL + "/user/create", { email, password });
}