import { Container } from "react-bootstrap";
import { Outlet } from "react-router-dom";
import PinPointNavbar from "../../Components/PinPointNavbar/PinPointNavbar";

export const Layout = () => {
  return (
    <>
      <PinPointNavbar />
      <Container>
        <Outlet />
      </Container>
    </>
  );
};
