import { Feature } from "ol";
import olView from "ol/View";
import { Point } from "ol/geom";
import { fromLonLat } from "ol/proj";
import { Fill, Stroke, Style, Text } from "ol/style";
import { useEffect, useMemo, useState } from "react";
import { getMunicipalities } from "../../Api/MunicipalityApi";
import Layers from "../../MapComponents/Layers/Layers";
import TileLayer from "../../MapComponents/Layers/TileLayer";
import VectorLayer from "../../MapComponents/Layers/VectorLayer";
import Map from "../../MapComponents/Map/Map";
import MyVectorSource from "../../MapComponents/Source/VectorSource";
import osm from "../../MapComponents/Source/osm";
import "./Municipalities.css";

export const Municipalities = () => {
  const [municipalities, setMunicipalities] = useState([]);

  const view = useMemo(
    () =>
      new olView({
        center: fromLonLat([-0.38466814, 51.404773]),
        zoom: 12,
      }),
    []
  );

  const style = useMemo(() => {
    return (feature)=>{ return new Style({
        text: new Text({
            font: '12px Calibri,sans-serif',
            fill: new Fill({ color: '#FF0000' }),
            stroke: new Stroke({
              color: '#fff', width: 2
            }),
            text: feature.get('name') + "\n" + feature.get('population')
          })
    })};
  })

  useEffect(() => {
    getMunicipalities().then((municipalities) => {
        const features = municipalities.map((municipality) => {
            let point = new Point(fromLonLat([municipality.location.coordinates[1], municipality.location.coordinates[0]]));
            let feature = new Feature(point);
            feature.set("name", municipality.name);
            feature.set("population", municipality.population);
            return feature;
        });

        setMunicipalities(features);
    });
  },[]);

  return (
    <>
      <h2>Municipalities {municipalities.length > 0 && "(" + municipalities.length + ")"}</h2>
      <div className="municipalitiesMapContainer">
        <Map view={view}>
          <Layers>
            <TileLayer source={osm()} zIndex={0} />
            <VectorLayer zIndex={1} style={style}>
                <MyVectorSource features={municipalities} />
            </VectorLayer>
          </Layers>
        </Map>
      </div>
    </>
  );
};
